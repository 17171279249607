import React, { useState, useEffect } from "react";
import "./usecases.css";
import Layout from "../components/layout";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from "../lib/helpers";
import BlogPostPreviewList from "../components/blog-post-preview-list";
import SEO from "../components/seo";
import "../components/home/platforms.module.css";
import AccordionScroll from "../components/accordion-scroll";

import OneClickTrading from "../images/OneClickTrading.png";
import DriftNotification from "../images/DriftNotification.png";
import LeavesDesignLeft from "../images/Left_leaves.png";
import AssetAllocation from "../images/AssetAllocation.png";
import dart from "../images/ClickIcon.png";
import people from "../images/people.png";
import notificationIcon from "../images/DriftNotificationIcon.png";
import ReInvest from "../images/Re_invest.png";
import Right_leave from "../images/Right_leave.png";
import SignUpImage from "../images/SignUpImage.png";
import { cn, generateTrackingPath, getAppBase, saveReferralCode, saveTrackingCode } from "../lib/helpers";
import Image_Placeholder from "../images/Image_Placeholder.png";
import FreeForQuestrade from "./free-for-questrade";

const PortfolioRebalancing = props => {
  const [active, setActive] = React.useState(0);
  const [registerPath, setRegisterPath] = useState("/register/");
  const appBase = getAppBase();
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  saveTrackingCode();
  saveReferralCode();

  useEffect(() => {
    setRegisterPath(generateTrackingPath("/register/"));
    changeBackground();
    window.addEventListener("scroll", changeBackground);
  }, []);

  const { data, questrade, errors } = props;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  return (
    <Layout>
      {
        <SEO
          title="Portfolio Rebalancing"
          description="Take control of your investing. Discover the benefits of automating your portfolio rebalancing process to keep your portfolio on target."
        />
      }

      <section className="Container_Class">
        {/* Top heading section */}
        <section className="First_heading">
          <div className="top_Header">
            <div className="header_Wrap">
              <h1>
                <span className="bold_text">Rebalancing Your Portfolio </span> with Ease
              </h1>
              <p>
                Automate your portfolio rebalancing to keep your portfolio investments on target.
              </p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Start Rebalancing
              </a>
              {questrade && <FreeForQuestrade />}
            </div>
            <div className="Image_background">
              <img src={OneClickTrading} alt="" role="presentation" />
            </div>
          </div>
        </section>

        {/* Second slanted grey section */}
        <section className="Slanted_background_grey">
          <div className="top_Header">
            <div className="Slanted_Image_background">
              <div className="Image_slanted">
                <img src={DriftNotification} alt="" role="presentation" />
              </div>
            </div>
            <div className="header_Wrap">
              <h4>THE PROBLEM</h4>
              <h2>I Have To Do Everything Myself</h2>
              <p>
                I have to monitor my portfolio constantly, set my asset allocation manually, and
                calculate endlessly how many assets I need to buy. That’s before I even execute the
                trades myself, one-by-one. And sometimes I just forget when my portfolio is out of
                balance. It takes a long time and a lot of effort.
              </p>
            </div>
          </div>
        </section>

        {/* leaves section */}
        <section className="left_leaves_design">
          <img src={LeavesDesignLeft} alt="" role="presentation" />
        </section>

        {/* Third section  */}
        <div className="header_Wrap_center">
          <div class="text_inside">
            <h4>THE SOLUTION</h4>
            <h2>Simplify The Process</h2>
            <p>
              Let Passiv monitor and then notify you when your portfolio changes. In Passiv, you can
              choose a target for your portfolio which is tracked as the market moves. Notifications
              instantly let you know when and what trades to make to rebalance, then lets you make
              all those trades in one click.
            </p>
          </div>
        </div>

        {/* Fourth section */}
        <div className="top_Header">
          <div className="header_Wrap_text">
            <h2>Take Control Of Your Portfolio</h2>
            <div className="list">
              <AccordionScroll active={active} setActive={setActive}>
                <details className="list_format" open={active === 0}>
                  <summary>Choose Your Assets</summary>
                  <p>
                    Choose a target for each asset as a percentage of your total portfolio. This
                    asset allocation target will be tracked by Passiv as the market moves, this is
                    your portfolio’s ‘accuracy’.
                  </p>
                </details>
                <details className="list_format" open={active === 1}>
                  <summary>Choose Your Drift Tolerance</summary>
                  <p>
                    As the market moves, your portfolio accuracy will drift from your target. Choose
                    an accuracy ‘drift threshold’ to be tracked by Passiv so you always know how
                    your portfolio is performing.
                  </p>
                </details>
                <details className="list_format" open={active === 2}>
                  <summary>Receive A Notification</summary>
                  <p>
                    When your portfolio accuracy drifts below the threshold target you chose, Passiv
                    sends you a notification to take action.
                  </p>
                </details>
                <details className="list_format" open={active === 3}>
                  <summary>Review Your Trade Recommendations</summary>
                  <p>
                    {" "}
                    Passiv calculates how much of each asset you need to buy to bring your portfolio
                    back to your target. Passiv will then preview the trades it recommends for you
                    to review.
                  </p>
                </details>
                <details className="list_format" open={active === 4}>
                  <summary>Make Your Trades In One Click</summary>
                  <p>
                    With the recommended trades previewed for you, you can then execute all of these
                    trades in a one single click.
                  </p>
                </details>
              </AccordionScroll>
            </div>
          </div>
          <div className="Image_background_large">
            <img src={AssetAllocation} alt="" role="presentation" />
          </div>
        </div>

        {/* Section slanted green background   */}
        <section className="Slanted_background_green">
          <div className="top_Header">
            <div class="text_wrap">
              <h2>Rebalance More Efficiently</h2>
              <p>When it’s time to rebalance your portfolio, Passiv has the right tools</p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Try These Features
              </a>
            </div>

            <div className="fragments">
              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={people} alt="" role="people" />
                  </div>
                  <h3>Multi-Account Portfolios</h3>
                  <p>
                    If you are managing accounts for multiple family members or have multiple
                    accounts for yourself, group your accounts into one or more portfolio groups and
                    manage them together in a single unified view.
                  </p>
                </div>
              </div>

              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={dart} alt="" role="dart" />
                  </div>
                  <h3>One Click Trading</h3>
                  <p>
                    Save yourself the hassle of logging into your broker to place trades,
                    one-by-one. Invest your cash according to your target portfolio and place trades
                    in one-click.
                  </p>
                </div>
              </div>

              <div className="tile">
                <div className="tileContainer">
                  <div className="ImageAlign">
                    <img src={notificationIcon} alt="" role="Notification" />
                  </div>
                  <h3>Drift Notifications</h3>
                  <p>
                    How close your current portfolio is to your chosen target portfolio is called
                    its ‘accuracy’. Drift Notifications instantly let you know when your portfolio
                    drifts from your chosen target due to market changes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Section vision */}
        <section className="Vision_header">
          <div className="top_Header">
            <div className="Image_background_wrap">
              <img src={ReInvest} alt="" role="presentation" />
            </div>
            <div className="header_Wrap">
              <h2>Simplify The Process</h2>
              <p>Do You Want To Make It Easier To Rebalance Your Portfolio?</p>
              <a className="btn1" href={`https://${appBase}${registerPath}`}>
                Yes, Simplify My Portfolio Rebalancing{" "}
              </a>
            </div>
          </div>
        </section>

        {/* right leaves section */}
        <section className="right_leaves_design">
          <img src={Right_leave} alt="" role="presentation" />
        </section>

        <section>
          <div className="top_Header_related_articles">
            {postNodes && (
              <BlogPostPreviewList title="Latest Posts" nodes={postNodes} browseMoreHref="/blog/" />
            )}
          </div>
        </section>

        <section className="Footer_section">
          <div className="top_Header">
            <div className="left_Footer">
              <div className="heading_wrap">
                <h2>Automate Your Learning</h2>
                <p>
                  Invest in yourself with The Index, our monthly newsletter to build your investing
                  confidence.
                </p>
                <div className="form">
                  <input type="text" placeholder="Your email"></input>
                  <a className="btn1" href={`https://${appBase}${registerPath}`}>
                    SUBSCRIBE
                  </a>
                </div>
                <p>Trusted by our 30,000+ subscribers.</p>
              </div>
            </div>
            <div className="Image_background">
              <div className="Image_Wrap"></div>
              <img src={SignUpImage} alt="" role="" />
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};

export default PortfolioRebalancing;
